import React from 'react'

import { snackData } from './snackData';
import { toppingsData } from './toppingsData';

export class Menu extends React.PureComponent {
  render() {
    return (
      <div className="container mx-auto p-8">
        <div className="menu-section mb-8">
          <h2 className="text-2xl font-semibold">MILK TEA</h2>
          <div className="menu-item-details">
            <p className="menu-item-title">REGULAR $4.50 | LARGE $5.50</p>
          </div>
          {["Banana", "Blueberry", "Classic black", "Chocolate", "Coconut", "Coffee", "Guava", "Honey", "Jasmine Green", "Kiwi", "Lychee", "Mango", "Brown Sugar", "Passion Fruit", "Peach", "Strawberry", "Taro", "Thai", "Tiger (Thai + Classic)", "Winter Melon"].map((title, index) => this.renderMenuItem({ title }, index))}
        </div>

        {/* FLAVORED TEA section */}
        <div className="menu-section mb-8">
          <h2 className="text-2xl font-semibold">FLAVORED TEA</h2>
          <div className="menu-item-details">
            <p className="menu-item-title">REGULAR $4.50 | LARGE $5.50</p>
          </div>
          {["Blueberry", "Classic black", "Guava", "Honey", "Jasmine green", "Kiwi", "Lemon", "Lychee", "Mango", "Passion fruit", "Peach", "Strawberry", "Watermelon", "Winter Melon"].map((title, index) => this.renderMenuItem({ title }, index))}
        </div>

        {/* SLUSHIES section */}
        <div className="menu-section mb-8">
          <h2 className="text-2xl font-semibold">SLUSHIES</h2>
          <div className="menu-item-details">
            <p className="menu-item-title">REGULAR $4.75 | LARGE $5.75</p>
          </div>
          {["Banana", "Blueberry", "Chocolate", "Coconut", "Coffee", "Guava", "Honey", "Kiwi", "Lemon", "Lychee", "Mango", "Passion Fruit", "Peach", "Strawberry", "Taro", "Watermelon", "Winter Melon"].map((title, index) => this.renderMenuItem({ title }, index))}
        </div>

        {/* TOPPINGS section */}
        <div className="menu-section mb-8">
          <h2 className="text-2xl font-semibold">TOPPINGS</h2>
          <p className="menu-item-title">$0.65 EACH</p>
          {/* <div className="menu-subsection">
            <h3 className="text-xl font-medium">Original</h3>
            {["Tapioca (boba)", "Agar tapioca"].map((title, index) => this.renderMenuItem({ title }, index))}
          </div>

          <div className="menu-subsection">
            <h3 className="text-xl font-medium">Jellies</h3>
            {["Aloe vera", "Lychee", "Coconut", "Rainbow", "Mango star"].map((title, index) => this.renderMenuItem({ title }, index))}
          </div>

          <div className="menu-subsection">
            <h3 className="text-xl font-medium">Popping Boba</h3>
            {["Blueberry", "Green apple", "Mango", "Passion fruit", "Pomegranate", "Strawberry"].map((title, index) => this.renderMenuItem({ title }, index))}
          </div> */}

          {toppingsData.map(this.renderMenuItem)}
        </div>
        <div>
          <h2 className="text-2xl font-semibold">SNACKS</h2>
          {snackData.map(this.renderMenuItem)}
        </div>
      </div>
    )
  }

  renderMenuItem(menuItem, key) {
    return <div key={key} className="menu-item">
      {menuItem.img !== undefined && <img src={menuItem.img} alt={menuItem.alt} className="menu-item-img" />}
      <div className="menu-item-details">
        <p className="menu-item-title">{menuItem.title}</p>
        <p>{menuItem.description}</p>
      </div>
    </div>
  }
}
