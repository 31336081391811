export const snackData = [
  {
    img: "/images/waffle_fries.png",
    alt: "Waffle fries",
    title: "Waffle fries - $4.00",
    description: "Indulge in our perfectly seasoned lattice-cut fries, served golden brown and crispy. Each order is accompanied by two sachets of our rich ketchup."
  },
  {
    img: "/images/spring_rolls.png",
    alt: "Spring rolls",
    title: "Veggie spring rolls (2) - $4.00",
    description: "Delicately hand-rolled in a light Chinese pastry, these spring rolls boast a medley of fresh vegetables including mushrooms, carrots, onions, cabbage, and green beans. Each order is complemented with two sachets of our aromatic duck sauce."
  },
  {
    img: "/images/cheese_sticks.png",
    alt: "Cheese sticks",
    title: "Cheese sticks (5) - $4.50",
    description: "Savor the rich flavors of our Mozzarella cheese sticks, enrobed in a seasoned batter and fried to perfection, offering a delightful cheesy experience with every bite."
  },
  {
    img: "/images/fried_dumpling.png",
    alt: "Fried dumplings",
    title: "Fried dumpling (6) - $5.00",
    description: "Experience the authentic taste of our dumplings, generously filled with pork, cabbage, and onion. Each order is enhanced with two sachets of Japanese Ponzu sauce, a tantalizing blend of citrus and soy."
  },
  {
    img: "/images/sugar_biscuit.png",
    alt: "Sugar biscuits",
    title: "Sugar biscuits (10) - $5.00",
    description: "Treat yourself to our delightful sugar biscuits, crafted from buttermilk biscuit dough, deep-fried to a golden hue, and generously dusted with fine granulated sugar."
  },
  {
    img: "/images/popcorn_chicken.png",
    alt: "Popcorn chicken",
    title: "Popcorn chicken - $6.50",
    description: "Relish the flavors of our signature popcorn chicken, crafted from tender chicken breast meat. Marinated in a unique blend of Chinese 5 spices and white pepper, each piece is fried fresh, ensuring a crispy bite every time."
  },
  {
    img: "/images/chicken_rice_bowl.png",
    alt: "Chicken rice bowl",
    title: "Chicken rice bowl (teriyaki, sweet chili, orange) - $7.00",
    description: "A comforting bowl of medium-grain white rice, generously topped with our signature popcorn chicken. Choose from our three delectable sauces, each drizzled over the rice and chicken for an immersive flavor experience."
  },
  {
    img: "/images/musubi.png",
    alt: "Musubi",
    title: "Musubi (2) - $5.00",
    description: "Cherish a bite of the islands with our SPAM® musubi. A slice of perfectly seasoned SPAM sits atop a bed of fluffy white rice, all wrapped in a sheet of toasted seaweed, resulting in a harmonious blend of flavors and textures."
  }
];
