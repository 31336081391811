import React from 'react'

export class Home extends React.PureComponent {
  render() {
    return (
      <>
        <p className="text-lg">
          Open Hours: 11:00 AM - 8:00 PM, Mon-Sat
        </p>

        <p className="text-lg">
          <a
            href="https://maps.app.goo.gl/oDZZAuzGafhRqfsu9"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-400 hover:underline hover:text-blue-600"
          >
            3460 West 25th St, Cleveland, Ohio 44109
          </a>
        </p>

        <iframe
          title="Mooncat Boba Cafe"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2990.034871431753!2d-81.70353592312708!3d41.46015899147698!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8830effd4113cb37%3A0xc98ed305ad15ec74!2s3460%20W%2025th%20St%2C%20Cleveland%2C%20OH%2044109%2C%20USA!5e0!3m2!1sen!2sil!4v1690207620812!5m2!1sen!2sil"
          className="w-[80%] h-[50%]"
          style={{border: 0}}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        HELLO
      </>
    )
  }
}
