

export const toppingsData = [
  {
    img: "/images/AGAR_TAPIOCA.png",
    alt: "Agar Tapioca",
    title: 'Agar Tapioca',
    description: "AKA. Crystal Boba. Subtle sweet, citrus flavor with a chewy, jelly-like texture. Made from konjac plant. (Vegetarian, Gluten-Free, Peanut-Free, Tree Nut-Free, Dairy-Free, Vegan)"
  },
  {
    img: "/images/LYCHEE JELLY.jpeg",
    alt: "Lychee Jelly",
    title: 'Jelly Jelly',
    description: "Translucent jellies with lychee flavor. Lychee is a fruit native to southeastern Asia. Sweet citrus like flavor. (Gluten-Free, Tree Nut-Free, Peanut Free, Vegetarian, Vegan, Dairy Free)"
  },
  {
    img: "/images/ALOE VERA.jpeg",
    alt: "Aloe Vera",
    title: 'Aloe Vera',
    description: "Crystal clear jelly, clean refreshing flavor with a hint of sweetness. Similar texture to a grape. Delicious and healthy. (Vegetarian, Tree Nut-Free, Peanut-Free, Dairy-Free, Gluten-Free, Vegan, Made with Natural Ingredients)"
  },
  {
    img: "/images/COCONUT JELLY.jpeg",
    alt: "Coconut Jelly",
    title: 'Coconut Jelly',
    description: "Translucent jellies, tropical coconut flavor and soft-jelly like texture. Made from natural coconut gel. (Dairy Free, Gluten-Free, Peanut Free, Tree Nut-Free, Vegan, Vegetarian)"
  },
  {
    img: "/images/RAINBOW JELLY.jpeg",
    alt: "Rainbow Jelly",
    title: 'Rainbow Jelly',
    description: "Red, orange, and white jellies. Sweet pineapple flavors. Soft, jelly-like texture. (Vegetarian, Tree Nut-Free, Peanut Free, Gluten-Free, Vegan, Dairy-Free)"
  },
  {
    img: "/images/MANGO STAR.jpeg",
    alt: "Mango Star",
    title: 'Mango Star',
    description: "Orange star shaped jellies. Ripe mango flavor. (Dairy Free, Gluten Free, Peanut Free, Tree Nut Free, Vegan, Vegetarian)"
  },
  {
    img: "/images/BLUEBERRY POPPING BOBA.jpeg",
    alt: "Blueberry Popping Boba",
    title: 'Blueberry Popping Boba',
    description: "Slightly tart blueberry flavor. Boba filled with blueberry juice that bursts with flavor in your mouth. (Gluten Free, Dairy Free)"
  },
  {
    img: "/images/GREEN APPLE POPPING BOBA.jpeg",
    alt: "Green Apple Popping Boba",
    title: 'Green Apple Popping Boba',
    description: "Refreshing, crisp green apple flavor. Boba filled with green apple juice that bursts with flavor in your mouth. (Gluten Free, Dairy Free)"
  },
  {
    img: "/images/MANGO POPPING BOBA.jpeg",
    alt: "Mango Popping Boba",
    title: 'Mango Popping Boba',
    description: "Ripe, juicy mango flavor. Boba filled with mango juice that bursts with flavor in your mouth. (Gluten Free, Dairy Free)"
  },
  {
    img: "/images/MANGO STAR.jpeg",
    alt: "Mango Star Jellies",
    title: 'Mango Star Jellies',
    description: "Orange star shaped jellies. Ripe mango flavor. (Dairy Free, Gluten Free, Peanut Free, Tree Nut Free, Vegan, Vegetarian)"
  },
  {
    img: "/images/PASSION FRUIT POPPING BOBA.jpeg",
    alt: "Passion Fruit Popping Boba",
    title: 'Passion Fruit Popping Boba',
    description: "Fresh, tropical passion fruit flavor. Boba filled with passion fruit juice that bursts with flavor in your mouth. (Gluten Free, Dairy Free)"
  },
  {
    img: "/images/POMEGRANATE POPPING BOBA.jpeg",
    alt: "Pomegranate Popping Boba",
    title: 'Pomegranate Popping Boba',
    description: "Sweet yet subtly tart pomegranate flavor. Boba filled with pomegranate juice that bursts with flavor in your mouth. (Gluten Free, Dairy Free)"
  },
  {
    img: "/images/STRAWBERRY POPPING BOBA.jpeg",
    alt: "Strawberry Popping Boba",
    title: 'Strawberry Popping Boba',
    description: "Juicy strawberry flavor. Boba filled with strawberry juice that bursts with flavor in your mouth. (Gluten Free, Dairy Free)"
  },
];
