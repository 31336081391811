import React from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import Layout from './Layout'
import { Home } from './Pages/Home'
import { Menu } from './Pages/Menu'

export class App extends React.Component {
    render() {
        return (
            <HashRouter>
                <Layout>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/menu" element={<Menu />} />
                    </Routes>
                </Layout>
            </HashRouter>
        )
    }
}
