import React from 'react'
import { Link } from 'react-router-dom'

import StampPng from './Assets/stamp.png'
import StampiPng from './Assets/stampi.png'
import InstaSvg from './Assets/insta.svg'

class Layout extends React.Component {
  render() {
    const { children } = this.props
    return (
      <div className="flex flex-col w-screen h-screen items-center gap-3 py-3">
        {this.renderHeader()}
        {this.renderNav()}
        {children}
        {this.renderFooter()}
      </div>
    )
  }

  renderHeader() {
    return (
      <div className="flex max-h-[25%] max-w-[25%] justify-center">
        <img src={StampPng} alt="Mooncat Boba Cafe" className="block dark:hidden" />
        <img src={StampiPng} alt="Mooncat Boba Cafe" className="hidden dark:block" />
      </div>
    )
  }

  renderNav() {
    return (
      <nav className="flex flex-row gap-4 justify-center items-center align-center text-lg">
        <Link to="/" className="hover:text-blue-600 transition-colors duration-300">Home</Link>
        <Link to="/menu" className="hover:text-blue-600 transition-colors duration-300">Menu</Link>
      </nav>
    )
  }

  renderFooter() {
    return (
      <footer className="gap-1">
        <div className="flex flex-col items-center align-center">
          <p className="font-semibold">Follow Us</p>
        </div>
        <div className="flex flex-row justify-center items-center align-center">
          <a href="//instagram.com/mooncatbobacafe">
            <img src={InstaSvg} alt="Instagram" className="w-[40px] h-[40px] hover:opacity-70 transition-opacity duration-300" />
          </a>
          <a href="//instagram.com/mooncatbobacafe" className="text-blue-400 hover:underline hover:text-blue-600 transition-colors duration-300">@mooncatbobacafe</a>
        </div>
        <div className="flex flex-col gap-2 justify-between items-center align-center">
          <p className="font-semibold">Contact Us</p>
          <p>
            <a href="mailto:hello@mooncatbobacafe.com" className="text-blue-400 hover:underline hover:text-blue-600 transition-colors duration-300">hello@mooncatbobacafe.com</a>
          </p>
        </div>
      </footer>
    )
  }
}

export default Layout
